import './App.css';
import CommonRoutes from './routes';

function App() {
  return (
    <div >
     <CommonRoutes />
    </div>
  );
}

export default App;
